'use strict';

import $ from 'jquery';

// This file is nothing more than a manifest of all teh old-school JS snippets we have jammed in here
// HUGE TODO: make these into classes and modules so they can be maintained using best practice.

import "../js/helpers/menu_tree_items"
import "../js/helpers/translations"

// NOTE requires https://yarnpkg.com/package/pdfjs-dist and worker-loader
// https://github.com/mozilla/pdf.js/tree/master/examples/webpack
import "../js/helpers/repository_asset_resource"

