import consumer from "./consumer"

consumer.subscriptions.create("PublishChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the Publish notifications.");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("Disconnected from the Publish notifications.");
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log("Publish Notification", data);
    // Replace our publish header with this
    $('.publish-notification').html(data);
  }
});
