document.addEventListener("turbolinks:load", function() {
	const $componet_select_inputs = $('input[id^=menu_tree_item_component_id]');

	// Load and display info for whatever the selected component is
	function loadSelectedComponentInfo() {
		const source = $("input[type=radio]:checked")
		const nameSource = source.data("source-name")
		const hrefSource = source.data("source-href")
		const infoSource = source.data("source-info")
		const infoCardHolder = $('#info-card-holder')

		$("#target-name").text(nameSource);
		$("#target-href").attr("href", hrefSource);

		if( undefined !== infoSource ){
			// load our info card and drop it into the results holder
			infoCardHolder.load( infoSource );
		}
	}

	// do this on page's first load and when we change selected component
	loadSelectedComponentInfo();
	$componet_select_inputs.change(function() {
		loadSelectedComponentInfo();
	});
});
