
document.addEventListener('turbolinks:load', function() {

	var formContentSelector = $('select.new_form_element_selector');

	var formContentButton = $('button.new_form_element_button');

	// If there is a selector, we should append or replace the content with the loaded partial
	var addSubform = function(input){
		var selector = $(this);
		//this is where we put the result
		var targetSelector = selector.data('target');
		//this is where we get the result
		var url = selector.data('url');
		//this is set if we are replacing not appending
		var replace = selector.data('replace') || false;
		var append = selector.data('append') || false;

		//this is the index of the next nested form object
		var nextRelationIndex = selector.data('next-relation-index');
		//optionally include an identifier for the context of this request; effectively a meta/info param.
		var contextIdentifier = selector.data('context-identifier');

		//console.log( 'loading new form data from:', url, ' index:', nextRelationIndex );

		$.ajax({
			dataType: 'html',
			cache: false,
			url,
			timeout: 5000,
			data: {
				selection: $(this).val(), //the value of the select box we picked
				next_relation_index: nextRelationIndex,
				context_identifier: contextIdentifier
			},
			error(XMLHttpRequest, errorTextStatus, error) {
				console.error('Failed to submit : ' + errorTextStatus + ' ;' + error);
				selector.val(null);
			},
			success(data) {
				var subForm = $(data).find('.loadable_content').html();
				if(replace) {
					console.log( 'Updating form data at:', targetSelector );
					$(targetSelector).html(subForm);
				} else if (append) {
					console.log( 'Appending new form data at:', targetSelector );
					$(targetSelector).append(subForm);
				} else {
					console.log( 'Preppending new form data at:', targetSelector );
					$(targetSelector).prepend(subForm);
				}
				//we updated our form so clear the selector and bump the next index to ask for
				selector.val(null);
				selector.data('next-relation-index', Number(nextRelationIndex) + 1);
				$('.tab-pane:not(.active) input').prop('disabled', true);
				$('.tab-pane:not(.active) textarea.form-control').prop('disabled', true);
			}
		});

	};

	// set our change handler for the filter
	formContentSelector.each(function(){
		$(this).on('change', addSubform);
	});

	// set our click handler for the button
	formContentButton.each(function(){
		$(this).on('click', addSubform);
	});

});
