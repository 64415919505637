'use strict';

import $ from 'jquery';

// This file is nothing more than a manifest of all teh old-school JS snippets we have jammed in here
// HUGE TODO: make these into classes and modules so they can be maintained using best practice.

import "../js/utils/global_utilities"
import "../js/utils/dasherize_input"
import "../js/utils/localizer"
import "../js/utils/snakeify_input"
import "../js/utils/tab_bar_status_tracker"
import "../js/utils/filterer"
import "../js/utils/modal_closer"
import "../js/utils/sortable_tables"
import "../js/utils/tabbed_pane_manager"
import "../js/utils/global_utilities"
import "../js/utils/new_form_content_manager"
import "../js/utils/start_app_build_modal"
import "../js/utils/load_alerts"
import "../js/utils/radio-highlighter"
import "../js/utils/submittable_inputs"
