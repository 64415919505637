// Ensure that the component type is global and os_values and multiliguals are unchecked to save the environment_variable_key
document.addEventListener("turbolinks:load", function() {

	var NavLinkSelector = $('.nav-tabs a, .nav-pills a');

	// This utility should toggle enable/disable fon form inputs that are in tabbed content.
	NavLinkSelector.on('shown.bs.tab', function(event){
		$(event.target.hash).find('input, textarea.form-control').prop('disabled', false);        // active tab
		$(event.relatedTarget.hash).find('input, textarea.form-control').prop('disabled', true);  // previous tab
	});

	// Our initial state we should disable any form-controls that are not 'active'
	$('.tab-pane:not(.active) input').prop('disabled', true);
	$('.tab-pane:not(.active) textarea.form-control').prop('disabled', true);

});