
import "@rails/ujs";
import Rails from "@rails/ujs";
import "jquery-ui/ui/widgets/sortable";

document.addEventListener("turbolinks:load", function() {

	var enableSorting = function() {
		$('.sortable').sortable({
			update(event, ui) {
				Rails.ajax({
					url: $(this).data("url"),
					type: "PATCH",
					data: $(this).sortable('serialize'),
				});
			}
		});
	}
	enableSorting();

	$('.toggle-sorting').on("click", function(e) {
		e.preventDefault();
		$('.can-be-sortable').addClass('sortable');
		$('.toggle-sorting').hide();
		enableSorting();
	});

});
