document.addEventListener("turbolinks:load", function() {


  $("td.editable_translation").focusout(function(){
      var $form = $(this).children("form");

      var locale = $form.children("input#translation_locale");

      var phrase = $form.children("input#translation_phrase");

      var currentValue = phrase.val();

      var newValue = $(this).text().trim();

      if(currentValue !== newValue){
        phrase.val(newValue);

        var form = $form[0];
        form.dispatchEvent(new Event("submit", {bubbles: true})); 
      }
    });
});