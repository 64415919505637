
document.addEventListener("turbolinks:load", function() {

	// All the elements on the page with a data-dasherize attibute
	var dasherizableInputSelector = $("input[data-dasherize]");

	// for some element with a dasherize selector, dasherize that elements value and set its dasherize target's value to the result
	var dasherizeInputToTarget = function(input){
		var target = $( $(this).data('dasherize') )
		if( target && '' === target.val() ) {
			target.val(input.target.value.toLowerCase().replace(/[\s]/gi, '-').replace(/[^\w\s-]/gi, ''));
		}
	};

	// set our change handler for each filter
	dasherizableInputSelector.each(function(){
		$(this).on('change', dasherizeInputToTarget);
	});

});

