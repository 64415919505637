// Selector of elements with .localizable-date class.
const LOCALIZABLE_DATES = () => $(".localizable-date");

// Localize date formated text.
$.fn.localize = function() {
	this.each(function(index, object) {
		const date = new Date($(object).text());

		$(object).text(
			date instanceof Date && !isNaN(date.getTime())
			? date.toLocaleString()
			: ""
		);
	});

	return this;
}

document.addEventListener("turbolinks:load", function() {
	LOCALIZABLE_DATES().localize();
});
