
document.addEventListener("turbolinks:load", function() {

	// All the elements on the page with a data-snakify attibute
	var dasherizableInputSelector = $("input[data-snakify]");

	// for some element with a snakify selector, snakify that elements value and set its snakify target's value to the result
	// the attribute data-target is a comma delimeted list of selectors.
	var snakifyInputToTarget = function(input){
		var targets = $(this).data("snakify");
		if (typeof targets === 'string') {
			targets.split(",").forEach( (target) => {
				if( $(target) && "" === $(target).val() ) {
					$(target).val(input.target.value.toLowerCase().replace(/[\s]/gi, "_").replace(/[^\w\s-]/gi, ""));
				}
			});
		}
	};

	// set our change handler for each filter
	dasherizableInputSelector.each(function(){
		$(this).on("change", snakifyInputToTarget);
	});

});

