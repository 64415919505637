// we use this to create pdf files for builds
// This previously used the older es5 code from the https://yarnpkg.com/package/pdfjs-dist
// NOTE requires https://yarnpkg.com/package/pdfjs-dist and worker-loader
// Webpack info here: https://github.com/mozilla/pdf.js/tree/master/examples/webpack
// pdfjsLib and pdfjsWorker are defined globally in config/webpack/environment

document.addEventListener("turbolinks:load", function() {

	const loadFile = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}

	const loadImage = (src) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => resolve(img);
			img.onerror = reject;
			img.src = src;
		});
	}

	const renderImageThumbnail = (data, $img) => {
		$img.attr("src", "data:image/png;base64,"+ data);
	}

	const renderImageSize = async (data, $img) => {
		const newImage = await loadImage("data:image/png;base64,"+ data);
		const sizetypeMatch = $img.data("repo-asset-resource-id").match(/(mdpi|hdpi|xhdpi|xxhdpi|xxxhdpi)/);

		$img
			.closest("tr")
			.find("td.asset-thumbnail-size")
			.text(`${newImage.width}x${newImage.height} ${sizetypeMatch ? sizetypeMatch[0] : ""}`);
	}

	// dynamically load img tags to offload encoded github images
	$("img[data-render-blob-url]").each(function() {
		var $img = $(this);
		var url = $img.data("render-blob-url");
		var repo_id = $img.data("repo-id");
		var file_type = $img.data("file-type");

		$.ajax({
			url: url,
			data: { repo_id: repo_id }
		}).done(function( data ) {
			renderImageThumbnail(data, $img);
			renderImageSize(data, $img);
		});
	});

	// use pdf.js to render pdf thumbs
	$("canvas[data-render-blob-url]").each(function() {
		var $canvas = $(this);
		var url = $canvas.data("render-blob-url");
		var repo_id = $canvas.data("repo-id");
		var file_type = $canvas.data("file-type");

		$.ajax({
			url: url,
			data: { repo_id: repo_id }
		}).done(function( data ) {
			render_pdf_thumbnail(data, $canvas);
		});
	});

	// render txt
	$("pre[data-render-blob-url]").each(function() {

		var $div = $(this);
		var url = $div.data("render-blob-url");
		var repo_id = $div.data("repo-id");
		var file_type = $div.data("file-type");

		$.ajax({
			url: url,
			data: { repo_id: repo_id }
		}).done(function( data ) {

			var dec = window.atob(data);
			$div.append("<xmp>" + dec + "</xmp>")
		});
	});

	$("form.new_repository_asset_resource").on("ajax:before", async function(event) {
		// Validate image size
		const newSrc = await loadFile($(this).find("input[type='file']").prop("files")[0]);
		const oldSrc = $(`img[data-repo-asset-resource-id=${$(this).find("#repository_asset_resource_id").val()}]`).attr("src");

		const newImage = await loadImage(newSrc);
		const oldImage = await loadImage(oldSrc);

		if (newImage.height !== oldImage.height || newImage.width !== oldImage.width) {
			$(this).find(".error-container").append($(
				`<p>Mismatched image size - expected: ${oldImage.height}x${oldImage.width}, actual: ${newImage.height}x${newImage.width}</p><br><strong>YOU MUST UPLOAD A NEW FILE OF THE PROPER SIZE<strong>`
			));
			$(this).find(".alert").show();
		} else {
			$(this).find(".error-container").empty();
			$(this).find(".alert").hide();
		}
	}).on("ajax:success", function(event) {
		const [data, status, xhr] = event.detail;

		var $form = $(this);

		var $row = $("tr#asset-row-" + data.id);

		// reset the form to be used again
		$form[0].reset();

		// update the repo sha so we can use the form again
		$form.find("#repository_asset_resource_asset_repo_sha").val(data.asset_repo_sha);

		// dismiss the form modal
		$("#asset-form-" + data.id).modal("hide");

		// update remove button sha
		$("#remove-button-" + data.id).siblings("[name=asset_repo_sha]").val(data.asset_repo_sha);
		$("#remove-button-" + data.id).siblings("[name=id]").val(data.id);
		$("#remove-button-" + data.id).show();

		// update updated_at
		$row.find("td.localizable-date").text(data.updated_at).localize();

		$row.removeClass("table-danger");
		$row.addClass("table-success");

		if(data.is_image || data.is_pdf) {
			// update the image in the listing to the one just uploaded
			$.ajax({
				url: "/repository_asset_resources/" + data.asset_repo_sha,
				data: { repo_id: $(this).data("assetRepoId") }
			}).done(function( thumb ) {
				var element = $("[data-repo-asset-resource-id='" + data.id  + "']");

				if(data.is_image){
					renderImageThumbnail(thumb, element);
					renderImageSize(thumb, element);
				}
				else if(data.is_pdf){
					render_pdf_thumbnail(thumb, element);
				}
			});
		}

	}).on("ajax:error", function(event) {
		const [data, status, xhr] = event.detail;

		$(this).find(".error-container").append($("<p>" + data + "</p>"));

		$(this).find(".alert").show();

	});

	var makeThumb = function makeThumb(page) {
		// draw page to fit into 96x96 canvas
		var vp = page.getViewport(1);
		var canvas = document.createElement("canvas");
		canvas.width = canvas.height = 96;
		var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
		return page.render({
			canvasContext: canvas.getContext("2d"),
			background: "rgba(0,0,0,0)",
			viewport: page.getViewport(scale)}).promise.then(function () {
				return canvas;
			});
	}

	var render_pdf_thumbnail = function(data, $canvas) {
		var pdfData = atob(data);

		pdfjsLib.GlobalWorkerOptions.workerPort = new pdfjsWorker();

		// Using DocumentInitParameters object to load binary data.
		var loadingTask = pdfjsLib.getDocument({data: pdfData});

		loadingTask.promise.then(function(doc) {
			var pages = []; while (pages.length < doc.numPages) pages.push(pages.length + 1);
			return Promise.all(pages.map(function (num) {
				// create a div for each page and build a small canvas for it
				return doc.getPage(num).then(makeThumb)
					.then(function (pdfCanvas) {
						Object.assign(pdfCanvas.dataset, $canvas[0].dataset);
						$canvas.replaceWith(pdfCanvas);
					});
			}));
		}).catch(console.error);
	}
});
