// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")


// core additions
import "jquery"
import "bootstrap"
// require("jquery")
// require("bootstrap")


// components for UI flavor
import "select2"
import "bootstrap-datepicker"
// require("select2")
// require("bootstrap-datepicker")

// Our home-made utils.
// TODO make all these into ES6 flavored modules the right way
import "./legacy_script"

// Helpers contain JS code specific to a controller's views
import "./helpers"

// Hold all the stylesheets in a styles folder within /app/frontend
import "../styles/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// import all our StimulusJS Controllers
import "controllers"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
