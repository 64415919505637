document.addEventListener("turbolinks:load", function () {
    //change colour when radio is selected
    function setHighlight(t) {
        $('div.highlight').removeClass('highlight');
        $(t).closest('.card').addClass('highlight');
    }
    $('.component-radios input:radio').change(function () {
        setHighlight(this);
    });
    $('.component-radios input:radio:checked').each( function(r) {
        setHighlight(this);
    });
});