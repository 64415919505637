
import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "is_shown_in_menu" ]

	set_shown_in_menu(event) {
		let formData = new FormData()
		formData.append("app_store_release_persona[is_shown_in_menu]", this.is_shown_in_menuTarget.checked);

		// This should be available through '@rails/ujs'...
		function getMetaValue(name) {
			const element = document.head.querySelector(`meta[name="${name}"]`)
			return element.getAttribute("content")
		}

		fetch(this.data.get("update-url"), {
			body: formData,
			method: 'PATCH',
			dataType: "script",
			contentType: "text/javascript",
			credentials: "include",
			headers: {
				"X-CSRF-Token": getMetaValue("csrf-token")
			},
		})
		.then(function(response) {
			if (response.status !== 204) {
				event.target.checked = !event.target.checked 
			}
		})
	}

	//This should pull the code out of our data-codes based on selected value
	//then update the target with that code.
	update_target_with_code(event) {
		var target = event.target.getAttribute('data-target');
		var codes = JSON.parse(event.target.getAttribute('data-codes'));
		document.getElementById(target).value = codes[event.target.value] || null
	}
}
