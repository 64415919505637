document.addEventListener('turbolinks:load', function() {
	const $filters = $('.filter input[type="checkbox"], .filter input[type="radio"]');
	const $filtereds = $('[data-filtered]');

	function doFilter() {
		const checkeds = $filters.filter(':checked');

		if (checkeds.length) { // filters enabled
			// Hide all
			$filtereds.hide();

			// Show filtered
			checkeds.each(function(_, checked) {
				$filtereds.filter(function(_, filtered) {
					return $.inArray($(checked).attr('id'), $(filtered).data('filtered')) !== -1;
				}).show();
			});
		} else { // filters disabled
			$filtereds.show();
		}
	}

	doFilter();

	$filters.each(function(_, filter) {
		$(filter).on('change', doFilter);
	});
});
