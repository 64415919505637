document.addEventListener('turbolinks:load', function () {
    $('.modal-closer').on("click", function (e) {
        e.preventDefault();
        var target = $(e.target);
        // find the open modal
        var theModal = $('.modal.show');
        theModal.on('hidden.bs.modal', function (e) {
            window.Turbolinks.visit(target.attr('href'));
        });
        theModal.modal('hide');
    });
});
