
document.addEventListener('turbolinks:load', function() {

    var alertCells = $('.loadable-alert');
    var checkStatus = function() {
        var self = $(this);
        $.ajax({
            url: self.attr('data-uri'),
            type: "get",
            dataType: "html",
            success: function(data) {
                // console.log("LoadAlerts data:", data);
                self.empty();
                self.html(data);
                //call the tooltip method here to activate the hover handler on the response
                $('[data-toggle="tooltip"]').tooltip();
                $('[data-toggle="popover"]').popover({
                    html: true
                });
            },
            fail: function(data) {
                console.error("LoadAlerts failed!", data);
                self.empty();
                self.html('Error checking status...');
            }
        });
    };
    alertCells.each(checkStatus);
});

