
document.addEventListener('turbolinks:load', function() {
	// Temporary easy buttons until we refactor build process code
	// CHNAGED: this should select teh kind of build you want as a defult but the user still needs to confirm
	$('.easy-button').on("click", function(e) {
		var btn = $(e.target);
		$('.custom-form').show();
		$('.modal-footer').show();
		if(btn.hasClass('build-prod')) {
			$('#ios_build_workflow_id').val('production-build');
			$('#ios_build_branch').val('develop');	
			$('#android_build_workflow_id').val('release');
			$('#android_build_branch').val('develop');
		}
		if(btn.hasClass('build-test')) {
			$('#ios_build_workflow_id').val('production-build');
			$('#ios_build_branch').val('develop');	
			$('#android_build_workflow_id').val('create_test_build');
			$('#android_build_branch').val('develop');	
		}	
		
		if(btn.hasClass('build-custom')) {
			$('#ios_build_workflow_id').val('production-build');
			$('#ios_build_branch').val('');	
			$('#android_build_workflow_id').val('create_test_build');
			$('#android_build_branch').val('');	
		}	
	});
});
